import React, { useEffect, useState } from 'react';
import { GetAuthData, PostAuthData } from '../../services/CallAPI';
import "./navBar.scss"

const ServerNavBar = () => {
    const [serverOnline, setServerOnline] = useState();
    const [serverUpdate, setServerUpdate] = useState();
    const [time, setTime] = useState();
    const [players, setPlayers] = useState();
    const [playersCounter, setPlayersCounter] = useState();

    useEffect(()=>{
        setInterval(() => {
            GetAuthData("/mc/status").then(data=>{
                if(data.success){
                    setServerOnline(data.values.value)
                    setServerUpdate(data.values.last_update)
                }
            })
        }, 1000);
    },[])
    useEffect(()=>{
        if(serverOnline){
            const interval = setInterval(()=>{
                GetAuthData("/mc/players").then(data=>{
                    if(data.success){
                        if(data.values.length > 0){
                            setPlayersCounter(data.values.length);
                            setPlayers(data.values);
                        } else {
                            setPlayersCounter(0);
                            setPlayers(null);
                        }
                    }
                })
            }, 1000)
            return () => clearInterval(interval)
        } 
    },[serverOnline])
    useEffect(()=>{
        if(serverUpdate){
            const interval = setInterval(()=>{
                const time = Date.parse(serverUpdate);
                const current_time = new Date();
                const diffTime = parseInt(current_time - time, 10);
                const diffHours = Math.floor(diffTime / (1000*60*60));
                const diffMinutes = Math.floor(diffTime / (1000*60) - diffHours * 60);
                const diffSeconds = Math.floor(diffTime / (1000) - diffHours * 60 * 60 - diffMinutes *60);
                setTime((diffHours?diffHours+"h ":"")+(diffMinutes?+diffMinutes+"m ":"")+diffSeconds+"s")
            },1000)
            return () => clearInterval(interval)
        }
    },[serverUpdate])

    return (
        <div className={"nav-bar-container flat "+(serverOnline?"online":"")}>
            <div className="nav-bar-server-status pressed">
                Status
            </div>
            <div className ="nav-bar-timer flat">
                {serverOnline?"Online: ":"Offline: "}{time}
            </div>
            <div className = {"players-list flat"}>
                <div className="player-text">PLAYERS ONLINE: {playersCounter!=undefined?playersCounter:""}</div>
                <div className = "players-list-container">
                    {players?players.map((player)=>{
                        return <div className="player-container flat">
                            <div className="nick">{player.nick}</div>
                            <img src={"https://mc-heads.net/avatar/"+player.nick}></img>
                        </div>
                    }):null}
                </div>
            </div>
        </div>
    );
}
 
export default ServerNavBar;