import React, { useEffect, useState } from 'react';
import "./progressButton.scss"

const ProgressButton = (props) => {
    const [clicked, setClicked] = useState(false);
    const [hideText, setHideText] = useState(false);
    const [width, setWidth] = useState(0);
    const [done, setDone] = useState(false);
    const [prevProgress, setPrevProgress] = useState();

    useEffect(()=>{
        if(props.disable){
            setClicked(true);
        } else {
            if(clicked && props.progress!=100){
                setClicked(false);
            }
        }
    }, [props.disable])
    const onClick = () => {
        if(!clicked){
            setClicked(true);
            props.onClick();
        }
    }
    useEffect(()=>{
        if(props.reset){
            setClicked(false);
            props.setReset(false);
        }
    },[props.reset])

    useEffect(()=>{
        if(props.progress > 0 && props.progress != 100){
            setClicked(true);
            setHideText(true);
        } else if(props.progress == 0){
            setHideText(false);
        }
        if(props.progress == 100){
            if(prevProgress != undefined){
                setTimeout(()=>{
                    setWidth(0);
                    setClicked(true);
                    setDone(true);
                    setHideText(false);
                }, 2000)
                setWidth(100);
            } else {
                setWidth(0);
                setClicked(true);
                setDone(true);
            }
        } else {
            setWidth(props.progress);
        }
        if(done && clicked && props.progress == 0){
            setTimeout(()=>{
                setClicked(false);
                setDone(false);
            },3000)
        }
        setPrevProgress(props.progress);
    }, [props.progress])

    return (
        <div className={"btn-progress-container " + (clicked?"active ":"") + props.color} onClick={(e)=>{onClick()}}>
            <div className="btn-progress-bar" style={{width: width+'%'}}/>
            <div className={"btn-progress-text " + (hideText?"hidden ":"")+(done?"done":"")}>{done?props.text_done:props.text}</div>
        </div>
    );
}
 
export default ProgressButton;