import React from 'react';
import LinkCard from '../components/linkCard/LinkCard';
import "../style/mainView.scss"

const MainView = (props) => {
    return ( 
        <div className="main-view-container">
            

            <LinkCard
                img= "truenas"
                name= "Free Nas"
                type= "external"
                link = "https://truenas.whcp.pl"
                color = "red"
            />
            {props.userLogin?
            <LinkCard
                img= "minecraft"
                name= "Server Minecraft"
                type= "internal"
                color = "green"
            />
            :null}
            
            <LinkCard
                img= "octopi"
                name= "Octo Print"
                type= "external"
                link = "https://octoprint.whcp.pl"
                color = "lime"
            />

            <LinkCard
                img= "nextcloud"
                name= "Next Cloud"
                type= "external"
                link = "https://nextcloud.whcp.pl"
                color = "blue"
            />

            
        </div>
    );
}
 
export default MainView;