import React from 'react';
import "./message.scss"

const Message = (props) => {
    return ( 
        <div className={"message "+props.color}>
            {props.message}
        </div> 
    );
}
 
export default Message;