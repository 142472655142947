import React, { useState, useEffect } from 'react';
import {GetAuthData} from "../services/CallAPI"
import {AnalyzeMessages, ProcessMessages} from "../services/ProcessMessages"
import Terminal from "../components/terminal/Terminal"
import ControllPanel from '../components/controllPanel/ControllPanel';
import "../style/serverView.scss"
import { Redirect } from 'react-router';

const ServerView = (props) => {
    const [startProgress, setStartProgress] = useState();
    const [stopProgress, setStopProgress] = useState();
    const [messages, setMessages] = useState(null);

    useEffect(()=>{
        if(startProgress == 100)
            setStopProgress(0);
    },[startProgress])

    useEffect(()=>{
        const interval = setInterval(() => {
            if(props.userLogin){
                GetAuthData("/server/messages").then(res => {
                    if(res.success){
                        let temp = ProcessMessages(res.values)
                        let settings = AnalyzeMessages(temp, startProgress, stopProgress);
                        setStartProgress(settings.startServerProgress);
                        setStopProgress(settings.stopServerProgress);
                        setMessages(temp);
                    }
                })
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [])

    return ( 
        props.userLogin?
            <div className="server-view">
            
            <div className="controll-panel">
                {startProgress!=null?
                <ControllPanel
                    startProgress = {startProgress}
                    stopProgress = {stopProgress}
                />
                :null
                
                }
                
            </div>
            <div className="terminal">
                {messages?
                    <Terminal
                        messages = {messages}
                    />
                :null}
            </div> 
        </div>
        :
        <Redirect to="/"/>
    );
}
 
export default ServerView;