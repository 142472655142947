import React from 'react';
import { useState } from 'react';
import "../style/loginView.scss"

const LoginView = (props) => {
    const [email, setEmail] = useState("");
    const [emailE, setEmailE] = useState("");
    const [password, setPassword] = useState("");
    const [passwordE, setPasswordE] = useState("");

    const login = async() =>{
        checkEmail();
        checkPassword();
        if(email && password){
            props.login(email, password);
        }
    }

    const checkEmail = () => {
        if(!email){
            setEmailE("Please enter your email!")
        } else {
            setEmailE("");
        }
    }

    const checkPassword = () => {
        if(!password){
            setPasswordE("Please enter your password!")
        } else {
            setPasswordE("");
        }
    }

    return ( 
        <div className="login-container flat">
            <div className ="logo-container">
                <img className="logo" src="../img/logo.svg"></img>
            </div>
            <div className="error main-error">{props.logMessage}</div>
            
            <div className="description">Email:</div>
            <div className="user-input">
                <img src={"../img/at.png"}/>
                <input className="email" onChange={(e)=>{setEmail(e.target.value); checkEmail()}}></input>
            </div>
            <div className="error">{emailE}</div>
            <div className="description">Password:</div>
            <div className="user-input">
                <img src={"../img/key.png"}/>
                <input className="password" type="password" onChange={(e)=>{setPassword(e.target.value); checkPassword()}}></input>
            </div>
            <div className="error">{passwordE}</div>
            <div className="btn btn-user-log-in" onClick={(e)=>{login()}}>Log In</div>
        </div>
    );
}
 
export default LoginView;