import React from 'react';
import { useEffect, useState } from 'react';
import { GetAuthData, PostAuthData } from '../../services/CallAPI';
import ProgressButton from '../progressButton/ProgressButton';
import "./controllPanel.scss"

const ControllPanel = (props) => {
    const [serverStatus, setServerStatus] = useState();
    const [wakeProgress, setWakeProgress] = useState();
    const [shutdownProgress, setShutdownProgress] = useState();
    const [resetWakeBtn, setResetWakeBtn] = useState(false);
    const [resetShutdownBtn, setResetShutdownBtn] = useState(false);
    const [btnBlocade, setBtnBlocade] = useState(false);

    useEffect(()=>{
        GetAuthData("/server/status").then((data)=>{
            if(data.success){
                if(data.data.value == 1){
                    let onTime = new Date(data.data.last_update);
                    let now = new Date();
                    let diffrence = Math.abs(now - onTime);
                    if(diffrence < 15000){
                        setServerStatus(false);
                        setTimeout(()=>{
                            setServerStatus(true);
                        },15000-diffrence)
                    } else {
                        setServerStatus(true);
                    }
                } else {
                    setServerStatus(false);
                }
            }
        })
    },[]);
    useEffect(()=>{
        if(serverStatus != null){
            if(serverStatus){
                setWakeProgress(100)
                setShutdownProgress(0)
                setBtnBlocade(false);
            } else {
                setWakeProgress(0)
                setShutdownProgress(100)
                setBtnBlocade(true);
            }
        }
        
    }, [serverStatus])
    const startServer = () => {
        PostAuthData("/mc/start").then((data)=>{
            console.log(data);
        })
    }
    const stopServer = () => {
        PostAuthData("/mc/stop").then((data)=>{
            console.log(data);
        })
    }
    const doBackup = () => {
        PostAuthData("/backup/create").then((data)=>{
            console.log(data);
        })
    }
    const wakeComputer = () =>{
        PostAuthData("/server/wake").then((data)=>{
            if(data.success){
                for(let i=1;i<=10;i++){
                    setTimeout(()=>{
                        setWakeProgress(i*10);
                        if(i === 10){
                            setShutdownProgress(0);
                            setBtnBlocade(false);
                        }
                    },1000*i)
                }
            } else {
                console.log("reset btn set")
                setResetWakeBtn(true);
            }
        })
    }
    const shutdownComputer = () =>{
        PostAuthData("/server/shutdown").then((data)=>{
            if(data.success){
                for(let i=1;i<=5;i++){
                    setTimeout(()=>{
                        setShutdownProgress(i*20);
                        if(i === 5){
                            setWakeProgress(0);
                            setBtnBlocade(true);
                        }
                    },1000*i)
                }
            } else {
                console.log("reset btn set")
                setResetShutdownBtn(true);
            }
        })
    }
    return (
        (serverStatus!=null && setWakeProgress!= null)?
        <div className="controll-panel-container">
             <div className="cp-section">
                <div className="cp-title">Computer</div>
                <ProgressButton
                    onClick = {wakeComputer}
                    reset = {resetWakeBtn}
                    setReset = {setResetWakeBtn}
                    text = {"Wake PC"}
                    text_done = {"PC Turned ON"}
                    progress = {wakeProgress}
                    disable = {false}
                    color = {"green"}
                />
                <ProgressButton
                    onClick = {shutdownComputer}
                    reset = {resetShutdownBtn}
                    setReset = {setResetShutdownBtn}
                    text = {"Shutdown PC"}
                    text_done = {"PC Turned OFF"}
                    progress = {shutdownProgress}
                    disable = {false}
                    color = {"red"}
                />
            </div>
            <div className="cp-section" style={{marginTop:20}}>
                <div className="cp-title">Minecraft Server</div>
                <ProgressButton
                    onClick = {startServer}
                    text = {"Start Server"}
                    text_done = {"Server Started"}
                    progress = {props.startProgress}
                    disable = {btnBlocade}
                    color = {"green"}
                />
                <ProgressButton
                    onClick = {stopServer}
                    text = {"Stop Server"}
                    text_done = {"Server Stopped"}
                    progress = {props.stopProgress}
                    disable = {btnBlocade}
                    color = {"red"}
                />
            </div>
            {/* <div className="cp-section"  style={{marginTop:20}}>
                <div className="cp-title">Backup</div>
                <ProgressButton
                    onClick = {doBackup}
                    text = {"Create Buckup"}
                    text_done = {"Backup Created"}
                    progress = {props.startProgress}
                    disable = {btnBlocade}
                    color = {"yellow"}
                />
            </div> */}
        </div>:""
    );
}
 
export default ControllPanel;