import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import "./navBar.scss";

const UserNavBar = (props) => {
    const [name, setName] = useState("Guest");

    useEffect(() => {
        if(props.userData){
            setName(props.userData.name)
        } else {
            setName("Guest")
        }
    }, [props.userLogin, props.userData]);

    return ( 
        <div className="usr-nav-bar-container flat">
            <img src="../img/man.png"/>
            <div className = "usr-email">{name}</div>
            {props.userLogin?
                <div className="btn usr-btn" onClick={(e)=>{props.logout()}}>Logout</div>
                
            :
                <Link to="/login">
                    <div className="btn usr-btn">Sign In</div>
                </Link>
            }
        </div> 
    );
}
 
export default UserNavBar;