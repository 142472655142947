

export function ProcessMessages(messages) {
  var ms = [];
  messages.forEach(message => {
    ms = ms.concat(processMessage(message.message))
  });
  return ms;
}

function processMessage (message) {
  message = message.replace(new RegExp("\r", "g"),'');
  let array = [];
  let elements = message.split("\n");
  elements.forEach(element=>{
    let color = "default"
    
    if(element.includes("\u001b[32m")){
      element = element.replace("\u001b[32m","");
      color = "green";
    }
    if(element.includes("\u001b[33m")){
      element = element.replace("\u001b[33m","");
      color = "yellow";
    }
    if(element.includes("INFO")){
      color = "green";
    }
    if(element.includes("WARN")){
      color = "yellow";
    }
    if(!element.includes("\u001b[m") && !element.includes("\u001b[K")){
      array.push({message: element, color: color})
    }
  })
  return array
}

export function AnalyzeMessages(messages, currentStartProgress, currentStopProgress){
  var startServerProgress = 0;
  var stopServerProgress = 100;
  messages.forEach(element =>{
    if(element.message.match(/[M,m]odLauncher running: args[^x]*/)){
      startServerProgress = 5;
    }
    if(element.message.match(/Environment/)){
      startServerProgress = 15;
    }
    if(element.message.match(/Starting version check at/)){
      startServerProgress = 40;
    }
    if(element.message.match(/Reloading ResourceManager/)){
      startServerProgress = 45;
    }
    if(element.message.match(/Starting Minecraft/)){
      startServerProgress = 50;
    }
    if(element.message.match(/Generating keypair/)){
      startServerProgress = 60;
    }
    if(element.message.match(/Using epoll channel type/)){
      startServerProgress = 70;
    }
    if(element.message.match(/Preparing level world/)){
      startServerProgress = 80;
    }
    if(element.message.match(/Preparing spawn area/)){
      startServerProgress = 90;
    }
    if(element.message.match(/[D,d]one[^x]*For help, type help/)){
      startServerProgress = 100;
      stopServerProgress = 0;
    }
    if(element.message.match(/Stopping the server/)){
      stopServerProgress = 5;
    }
    if(element.message.match(/Saving chunks for level/)){
      stopServerProgress = 50;
    }
    if(element.message.match(/ThreadedAnvilChunkStorage \(DIM1\)/)){
      stopServerProgress = 100;
      startServerProgress = 0;
    }
  })
  return {
    startServerProgress: startServerProgress,
    stopServerProgress: stopServerProgress
  }
}