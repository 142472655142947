export async function GetData(card) {
  let ApiUrl = "/rest/v1" + card;
  const response = await fetch(ApiUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  return response.json();
}

export async function PostData(card, data) {
  let ApiUrl = "/rest/v1" + card;
  const response = await fetch(ApiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(data),
  });
  return response.json();
}

export async function GetAuthData(card) {
  let token = getToken()
  if(token){
    let ApiUrl = "/rest/v1" + card;
    const response = await fetch(ApiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer "+token
      },
    });
    return response.json();
  } else {
    return false
  }
}

export async function PostAuthData(card, data) {
  let token = getToken()
  if(token){
    let ApiUrl = "/rest/v1" + card;
    const response = await fetch(ApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer "+token
      },
      body: JSON.stringify(data),
    });
    return response.json();
  } else {
    return false;
  }
}

const getToken = ()=>{
  try {
    if (localStorage.getItem("token") != null) {
      return localStorage.getItem("token");
    }
  } catch (error) {
    console.log(error);
    return false;
  }
  return false;
}
  