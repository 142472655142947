import React, { Component, useEffect, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import Message from '../message/Message';
import {PostAuthData} from "../../services/CallAPI";
import "./terminal.scss";

const Terminal = (props) => {
    const [progress, setProgress] = useState(false);
    const [autoScroll, setAutoScroll] = useState(true);
    const [command, setCommand] = useState();
    const scrollableNodeRef = React.createRef();
    
    function pageScroll() {
        scrollableNodeRef.current.scrollTop += 1000;
    }
    useEffect(()=>{
        if(autoScroll){
            pageScroll()
        }
    },[props.messages])

    const sendCommand = () =>{
        if(!progress){
            pageScroll();
            setProgress(true);
            setTimeout(()=>{
                setProgress(false);
            }, 1000);
            PostAuthData("/server/sendcommand", {command: command}).then((data)=>{
            })
        }
    }

    return ( 
        <div className="terminal-container flat">
            <div className="terminal-window pressed" id="terminal">
                <SimpleBar 
                    scrollableNodeProps={{ ref: scrollableNodeRef }}
                    autoHide={false}
                    style={{maxHeight: 400}}>
                    {props.messages.map(element => {
                        return(
                            <Message
                                message = {element.message}
                                color = {element.color}
                            />
                        )  
                    })}
                </SimpleBar>
            </div>
            <div className="terminal-send-container">
                <div className="terminal-input">
                    <input className="pressed" value={command} onChange={(e)=>{setCommand(e.target.value)}} placeholder="Type command..."></input>
                </div>
                <button className={"terminal-send btn "+ (progress?"active":"")} onClick={sendCommand}>
                    SEND
                </button>
            </div>
            <div className="terminal-checkbox-container">
                    <p>Auto scroll</p>
                    <div className="terminal-checkbox">
                        <input
                            checked={autoScroll}
                            onClick={(e)=>{setAutoScroll(!autoScroll)}}
                            type="checkbox"
                            id="checkbox"
                        />
                    </div>
                </div>
        </div>
     );
}
 
export default Terminal;