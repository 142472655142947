import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { GetAuthData, PostData } from './services/CallAPI';
import ServerView from "./views/ServerView";
import "./style/index.scss"
import './App.css';
import MainView from "./views/MainView";
import ServerNavBar from "./components/serverNavBar/ServerNavBar";
import UserNavBar from "./components/userNavBar/UserNavBar";
import LoginView from "./views/LoginView";

function App() {
  const [darkMode, setDarkMode] = useState(true);
  const [userLogin, setUserLogin] = useState(false);
  const [userData, setUserData] = useState();
  const [logMessage, setLogMessage] = useState("");

  useEffect(() => {
    if (userLogin) {
      GetAuthData("/user/info").then((data) => {
        if (!data.success) {
          setUserLogin(false);
        } else {
          setUserData(data.user);
          console.log(data);
        }
      });
    }
  }, [userLogin]);
  useEffect(() => {
    try {
      if (localStorage.getItem("token") != null) {
        setUserLogin(true);
        console.log("user is logged in!");
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const logout = () => {
    localStorage.removeItem("token");
    setUserLogin(false);
    setUserData(null);
    setLogMessage("");
  };
  const login = (email, password) =>{
    PostData("/user/login",{email:email, password:password}).then((data) => {
      if(!data.success){
        setUserLogin(false)
        setLogMessage(data.error);
      } else {
        localStorage.setItem("token", data.accessToken);
        setUserLogin(true);
        setLogMessage(data.message);
      }
    })
  }

  return (
    <Router>
      <div className={darkMode ? "App dark-mode" : "App light-mode"}>
      {userLogin?<ServerNavBar/>:null}
        <UserNavBar
          userLogin = {userLogin}
          userData = {userData}
          logout = {logout}
        />
        <Switch>
          <Route exact path="/">
            <MainView
              userLogin = {userLogin}
            />
          </Route>
          <Route exact path="/server">
            <ServerView
              userLogin={userLogin}
            />
          </Route>
          {userLogin?
            <Route path="/">
            <Redirect to="/"/>
            </Route>
            :
            <>
            <Route exact path="/login">
              <LoginView
                login = {login}
                logMessage = {logMessage}
              />
            </Route>
            <Route path="/">
              <Redirect to="/login"/>
            </Route>
            </>
          }
          
        </Switch>
      </div>
    </Router>
  );
}

export default App;
