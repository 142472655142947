import React from 'react';
import { Link } from 'react-router-dom';
import "./linkCard.scss";

const LinkCard = (props) => {
    return ( 
        <div className={"link-card-container pressed "+props.color}>
            <div className="card">
                <img 
                    src={"../img/"+props.img+".png"} 
                    alt={props.name}
                />
                <div className="card-title">
                    {props.name}
                </div>
                {props.type == "internal"?
                    <Link to="/server">
                        <div className="card-btn-container">
                            <div className="card-btn-text">
                                Launch
                            </div>
                        </div>
                    </Link>
                :
                    <a href={props.link} target="_blank">
                        <div className="card-btn-container">
                            <div className="card-btn-text">
                                Launch
                            </div>
                        </div>
                    </a>
                }
            </div>
        </div>
    );
}
 
export default LinkCard;